import { AboutProps } from './components/About';
import { ContactProps } from './components/Contact';
import { NavProps } from './components/Nav';
import { ServiceProps } from './components/Services';
import { SideBarProps } from './components/Sidebar';
import { TestimonialsProps } from './components/Testimonials';
import { WorkProps } from './components/Work';

export interface Config {
    sidebar?: SideBarProps;
    about?: AboutProps;
    services?: ServiceProps;
    work?: WorkProps;
    testimonials?: TestimonialsProps;
    contact?: ContactProps;
    nav?: NavProps;
}

export const config: Config = {
    nav: {
        items: [
            {
                navigateTo: 'work',
                smooth: true,
                offset: -70,
                duration: 500,
                text: 'Work',
            },
            {
                navigateTo: 'services',
                smooth: true,
                offset: -70,
                duration: 500,
                text: 'Services',
            },
            {
                navigateTo: 'about',
                smooth: true,
                offset: -70,
                duration: 500,
                text: 'About',
            },
            {
                navigateTo: 'testimonials',
                smooth: true,
                offset: -70,
                duration: 500,
                text: 'Testimonials',
            },
            {
                navigateTo: 'contact',
                smooth: true,
                offset: -70,
                duration: 500,
                text: 'Contact',
            },
            // {
            //     navigateTo: '/blog',
            //     smooth: true,
            //     offset: -70,
            //     duration: 500,
            //     text: 'Blog',
            // },
        ],
    },
    sidebar: {
        twitter: 'https://x.com/iamrishavanand',
        linkedin: 'https://linkedin.com/in/rishavanand',
        github: 'https://github.com/rishavanand',
        email: 'contact@rishavanand.com',
    },
    about: {
        title: {
            value: 'Let me tell you a bit more about me',
            subTitle: 'A brief intro.',
            subTitleFontStyle: 'secondary',
        },
        description: [
            'As a seasoned Principal Architect, I specialize in developing innovative software solutions that enhance performance and deliver unique features. My expertise includes JavaScript, TypeScript, Python, SQL, ReactJS, NodeJS, MongoDB, PostgreSQL, AWS, Azure, Ethereum, Git, and Docker. I have successfully integrated payment systems, created dynamic frontends, implemented real-time features, and deployed scalable solutions.',
            `One of my notable projects is the "GitHub Profilinator," which has earned over 1,100 stars on GitHub. I hold certifications as an AWS Solutions Architect Associate and a Microsoft Azure Developer Associate, underscoring my proficiency in cloud technologies.`,
            'My professional experience includes roles as a Principal Architect, Software Consultant and Lead Software Developer, where I collaborated with teams to deliver robust solutions. Driven by a passion for technology and a commitment to excellence, I am always eager to embrace new challenges and achieve exceptional results.',
        ],
        signature: 'Rishav',
        profilePicture: 'me.jpg',
        profilePictureOverlayText: 'Remote since',
        profilePictureOverlayShinyText: '2013',
        certificates: [
            {
                link: 'https://www.credly.com/badges/49f0f85e-e04c-4d1c-9d67-557967c07fd3/linked_in_profile',
                badgeImageLink: 'AWS-Certified-Solutions-Architect-Associate_badge.png',
                title: 'AWS Certified Solutions Architect – Associate',
            },
            {
                link: 'https://learn.microsoft.com/en-us/users/rishavanand-4685/credentials/44e3bf99c2ed9ca5',
                badgeImageLink: 'azure-developer-associate.png',
                title: 'Microsoft Certified: Azure Developer Associate',
            },
        ],

        dottedPattern: {
            height: 30,
            width: 40,
            top: 0,
            left: 0,
        },
    },
    services: {
        title: {
            value: 'What type of project do you need help with?',
            subTitle:
                "I'll work closely with you to design a new product or redesign an existing website while striving for tangible business outcomes.",
            subTitleFontStyle: 'secondary',
        },
        items: [
            {
                title: {
                    value: 'Websites & Web Apps',
                    accent: false,
                    size: 'h2',
                    alignment: 'left',
                    marginTop: 3,
                },
                points: [
                    'You have an existing app or website that needs more features.',
                    'You have an idea that needs execution through custom app development work.',
                    'You have an existing app or website that needs a performance boost through optimizations.',
                    'You have an existing website that needs to be converted into a mobile app.',
                ],
                coverImageUrl: '/website.png',
                coverImageUrlDark: '/website-dark.png',
            },
            {
                title: { value: 'Cloud Services', accent: false, size: 'h2', alignment: 'left', marginTop: 3 },
                points: [
                    'You have an existing cloud service that needs more features.',
                    'You have an idea that needs execution through custom cloud development and deployment.',
                    'You have an existing app or website that needs a performance boost through scaling and optimizations. ',
                    'You have an existing app that needs to be converted into a cloud application.',
                ],
                coverImageUrl: '/cloud.png',
                coverImageUrlDark: '/cloud-dark.png',
            },
            // {
            //     title: {
            //         value: 'Blockchain Networks',
            //         accent: false,
            //         size: 'h2',
            //         alignment: 'left',
            //         marginTop: 3,
            //     },
            //     points: [
            //         'You have an existing blockchain network that needs more features.',
            //         'You have an idea that needs execution through blockchain and cryptocurrency technologies.',
            //         'You have an existing network that needs to be scaled to meet your increasing user base.',
            //         'You have a service that needs integration with a public chain.',
            //     ],
            //     coverImageUrl: '/blockchain.png',
            //     coverImageUrlDark: '/blockchain-dark.png',
            // },
        ],
        dottedPattern: {
            height: 30,
            width: 50,
            bottom: 10,
            right: 0,
        },
    },
    work: {
        title: {
            value: 'Featured Work',
            subTitle: 'I design and build digital products that are both beautiful and robust.',
            subTitleFontStyle: 'secondary',
        },
        items: [
            {
                title: {
                    value: 'minisocial',
                    size: 'h3',
                },
                link: 'https://minisocial.com/',
                type: 'Digital Product - Complete E2E Solution',
                tags: ['Full Stack', 'HubSpot CRM', 'APIs', 'Dashboard'],
                coverImageUrl: '/minisocial.png',
                summary:
                    'Minisocial is a fully managed platform that connects brands with micro-influencers to produce user-generated content (UGC) on demand and at scale. By collaborating with micro-influencers, minisocial enables brands to showcase their products authentically, leveraging content from real people across various marketing channels, including social media, email, and websites.',
            },
            {
                title: {
                    value: 'Headway.ai',
                    size: 'h3',
                },
                link: 'https://telusinternational.headway.ai/',
                type: 'Digital Product - Complete E2E Solution',
                tags: ['Full Stack', 'APIs', 'Dashboard', 'Dev Ops', 'System Design'],
                coverImageUrl: '/headway.png',
                summary:
                    'Headway.ai is a talent intelligence platform that leverages artificial intelligence (AI) and behavioral science to streamline and enhance the recruitment process for enterprises. Its AI-driven bots automate key hiring stages-sourcing, assessing, and interviewing candidates-delivering a ready-to-hire talent pool efficiently.',
            },
            {
                title: {
                    value: 'RewardMatrix',
                    size: 'h3',
                },
                link: 'https://www.rewardmatrix.in/',
                type: 'Digital Product - Complete E2E Solution',
                tags: ['Full Stack', 'APIs', 'SSR', 'NextJS', 'SEO'],
                coverImageUrl: '/reward-matrix.png',
                summary:
                    'RewardMatrix is a platform that assists users in discovering and comparing top-rated credit cards to identify the most rewarding options based on their spending habits. It offers tools such as card reviews, a reward calculator, a comparison matrix, and expense planning features. The service is user-friendly, requiring no sign-up and is free from advertisements.',
            },
            // {
            //     title: {
            //         value: 'DataWallet',
            //         size: 'h3',
            //     },
            //     type: 'Digital Product',
            //     tags: ['Full Stack', 'Cloud', 'APIs', 'Dashboard'],
            //     coverImageUrl: '/datawallet.jpg',
            //     mobileCoverImageUrl: '/datawallet-mobile.jpg',
            //     summary:
            //         'A single place to store all your data, from browser history to medical records. I created a distributed cloud storage platform with Braincities Lab. In addition to being a cloud storage, it utilizes the Blockchain technology to provide security and reliability.',
            // },
            {
                title: {
                    value: 'Terrascope',
                    size: 'h3',
                },
                type: 'Digital Product - ESG Reporting Module',
                tags: ['Full Stack', 'Mongo', 'ReactJS', 'Dashboard'],
                coverImageUrl: '/terrascope.jpg',
                summary: `Terrascope's platform includes a comprehensive reporting module designed to assist companies in effectively disclosing their greenhouse gas (GHG) emissions. This module facilitates compliance with various environmental, social, and governance (ESG) reporting standards, such as the Carbon Disclosure Project (CDP) and the Task Force on Climate-related Financial Disclosures (TCFD).`,
                link: 'https://terrascope.com/',
            },
            {
                title: {
                    value: 'Virtooally',
                    size: 'h3',
                },
                type: 'Digital Product - Complete E2E Solution',
                tags: ['Full Stack', 'Real Time', 'APIs', 'Dashboard'],
                coverImageUrl: '/virtooally.png',
                summary:
                    'I worked with Exponents Insta USA Inc. to create a powerful new interaction layer for their existing virtual exhibitions. This layer offers real-time features such as video calling, web chatting, file downloading, user tracking, and lead management for a truly immersive experience.',
                link: 'https://dashboard.virtooally.com/',
            },
            {
                title: {
                    value: 'Magnet',
                    size: 'h3',
                },
                type: 'Digital Product - Complete E2E Solution',
                tags: ['Full Stack', 'APIs', 'Dashboard', 'Landing'],
                coverImageUrl: '/magnet.jpg',
                summary:
                    'Magnet is a platform that enables communities to create and share video content. Users can initiate a "Magnet" to which community members respond with their own videos. These contributions can be reposted, remixed, and repurposed as desired.',
                link: 'https://withmagnet.com/',
            },
            {
                title: {
                    value: 'Louloucup',
                    size: 'h3',
                },
                type: 'Internal App - Shipping & Inventory Module',
                tags: ['Backend', 'Shopify', 'APIs', 'Dashboard'],
                coverImageUrl: '/louloucup.png',
                summary:
                    '   Louloucup is a French brand specializing in eco-friendly menstrual products, including menstrual cups and period panties. Their offerings are designed to provide comfortable, sustainable alternatives to traditional feminine hygiene products.',
                link: 'https://en.louloucup.com/',
            },
            // {
            //     title: {
            //         value: 'UniComm',
            //         size: 'h3',
            //     },
            //     type: 'Internal App',
            //     tags: ['Full Stack', 'Mobile App', 'Firebase', 'Video Call'],
            //     coverImageUrl: '/unicomm.png',
            //     summary:
            //         'An internal university app for students and teachers to facilitate better communication. It is bundled with features like chat, file downloads, video calls and appointment booking. A cross platform app, made with React Native, that runs the same code on Android and iOS devices. ',
            // },
        ],
        dottedPattern: {
            height: 40,
            width: 30,
            bottom: 10,
            left: 0,
        },
    },
    testimonials: {
        title: {
            value: 'What people have to say',
            subTitle:
                'I have developed complex web software for startups and businesses to directly impact thousands of users.',
            subTitleFontStyle: 'secondary',
        },
        dottedPattern: {
            height: 20,
            width: 40,
            bottom: 10,
            right: 0,
        },
        items: [
            {
                title: {
                    value: 'Kaze A. ONGUENE',
                    size: 'h3',
                    accent: false,
                    subTitle: 'CEO @ Braincities Lab',
                    subTitleFontStyle: 'secondary',
                    marginBottom: 0,
                    alignment: 'left',
                },
                logo: {
                    imageUrl: '/braincities-logo.png',
                },
                testimonial: `"When you combine talent and hard work you can reach amazing results. Rishav is an
                incredible engineer, humble, volunteer, he always overcomes my expectation. It has been
                and it will always be a pleasure building, breaking, improving incredible pieces of
                technology with him. Thank you for your hard work."`,
            },
            {
                title: {
                    value: 'R. A. Lelito',
                    size: 'h3',
                    accent: false,
                    subTitle: 'Product Developer @ Distractive Media, Inc',
                    subTitleFontStyle: 'secondary',
                    marginBottom: 0,
                    alignment: 'left',
                },
                logo: {
                    imageUrl: '/distractive-media-logo.jpeg',
                    rounded: true,
                },
                testimonial: `"I had the honor of working with Rishav for approximately two (2) years. Without
                exception, Rishav is/was one of the best backend developers I know. As a senior
                executive, I have managed more than 200 personnel at any given time for such global tech
                companies as WorldCom, GTE, Verizon, & Bechtel. From operations to product development.
                Rishav excelled at every challenge presented. His attitude is formidable. His knowledge
                superior. His curiosity is limitless. And his desire to succeed unparalleled. If you
                ever have the opportunity to work with Rishav, you will indeed be honored and impressed
                with his character and integrity."`,
            },
            {
                title: {
                    value: 'Linda Ravindran',
                    size: 'h3',
                    accent: false,
                    subTitle: 'Co-Founder @ Headway.ai',
                    subTitleFontStyle: 'secondary',
                    marginBottom: 0,
                    alignment: 'left',
                },
                logo: {
                    imageUrl: '/headway-logo.png',
                    rounded: true,
                },
                testimonial: `"Rishav is quick at relating to the use case, designing & adept in backend and frontend development. He brings interesting perspectives to the discussion that help in solutioning with ease. He has a keen sense of curiosity, likes to delve deep into complex problems & great attention to detail. He is articulate with a calm and composed demeanor. It's great to work with him!"`,
            },
            {
                title: {
                    value: 'Devendra Patkie',
                    size: 'h3',
                    accent: false,
                    subTitle: 'COO @ Insta Group',
                    subTitleFontStyle: 'secondary',
                    marginBottom: 0,
                    alignment: 'left',
                },
                logo: {
                    imageUrl: '/insta-logo.jpg',
                    rounded: true,
                },
                testimonial: `"Rishav is a self motivated and competent professional in the software development field. He understands the customer requirements in detail and provides solutions within his domain; strives for improvement till he gets the green flag from the stakeholders.
He is also well versed with the business targets that his clients want to achieve. Overall, a reliable and highly recommended developer."`,
            },
            {
                title: {
                    value: 'Rohan Prasad',
                    size: 'h3',
                    accent: false,
                    subTitle: 'Software Engineer @ Microsoft',
                    subTitleFontStyle: 'secondary',
                    marginBottom: 0,
                    alignment: 'left',
                },
                logo: {
                    imageUrl: '/microsoft-logo.png',
                },
                testimonial: `"Rishav and I worked together in many projects be it a Hackathon , Commercial or an
                Opensource project and had a great experience with him. He is highly passionate about
                technolgies and loves to bring them into implementation quickly and see things moving
                around. He also has a great planning skills as well which always adds an advantage to
                the team he is working with."`,
            },
            {
                title: {
                    value: 'Mayank Pathela',
                    size: 'h3',
                    accent: false,
                    subTitle: 'Full Stack Developer II @ Derq',
                    subTitleFontStyle: 'secondary',
                    marginBottom: 0,
                    alignment: 'left',
                },
                logo: {
                    imageUrl: '/derq.png',
                },
                testimonial: `"I had worked alongside Rishav on many projects and discussed various aspects of
                implementing software solutions for our use case. He had a positive attitude and
                dedication to his work. It was great working with him."`,
            },
        ],
    },
    contact: {
        title: {
            value: 'Got a project in mind?',
            subTitle:
                "I can't wait to learn about your project. Here are a few questions to help me understand your specific needs and I'll be in touch very soon.",
            subTitleFontStyle: 'secondary',
        },
    },
};

export default config;
