import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'gatsby';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import React, { useState } from 'react';
import styled from 'styled-components';
import { DottedPatternProps } from './DottedPattern';
import { Section, SectionWrapper } from './Section';
import Title, { TitleProps } from './Title';

export interface WorkItem {
    title?: TitleProps;
    link?: string;
    coverImageUrl?: string;
    mobileCoverImageUrl?: string;
    itemIndex?: number;
    summary?: string;
    location?: string;
    type?: string;
    tags?: string[];
}

export interface WorkProps {
    id?: string;
    title?: TitleProps;
    items?: WorkItem[];
    dottedPattern?: DottedPatternProps;
}

const Label = styled.span`
    background-color: white;
    color: black;
    padding: 2px 4px;
    border-radius: 6px;
    font-size: 0.9rem;
`;

const WorkList = styled.div`
    margin-top: 4rem;
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 4rem;
    gap: 1.5rem;
`;

const WorkItemWrapper = styled.div<{ mobile?: boolean; itemIndex: number }>`
    grid-column: ${props => (props.itemIndex && !props.mobile ? 'span 1' : 'span 2')};
`;

const WorkImage = styled.img<{ mobile?: boolean; itemIndex: number }>`
    width: 100%;
    border-radius: ${props => (!props.mobile && props.itemIndex === 0 ? '6px' : undefined)};
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    -webkit-box-shadow: 0px 2px 5px 0px rgba(72, 117, 178, 0.15);
    -moz-box-shadow: 0px 2px 5px 0px rgba(72, 117, 178, 0.15);
    box-shadow: 0px 2px 5px 0px rgba(72, 117, 178, 0.15);
    transition: transform 0.4s;

    &:hover {
        transform: scale(1.05);
    }
`;

const WorkDescription = styled.div<{ mobile?: boolean; itemIndex: number }>`
    flex-grow: 1;
    font-size: 0.9 rem;
    padding: 1.5rem;
    text-align: left;
    position: relative;
    background-color: var(--bgSecondary);
    color: var(--textNormal);
    border: var(--bgSecondary);
    top: ${props => (!props.mobile && props.itemIndex === 0 ? '-3rem' : undefined)};
    left: ${props => (!props.mobile && props.itemIndex === 0 ? '1.5rem' : undefined)};
    width: ${props => (!props.mobile && props.itemIndex === 0 ? '40%' : undefined)};
    border-radius: ${props => (!props.mobile && props.itemIndex === 0 ? '6px' : undefined)};
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    -webkit-box-shadow: 0px 2px 5px 0px rgba(72, 117, 178, 0.15);
    -moz-box-shadow: 0px 2px 5px 0px rgba(72, 117, 178, 0.15);
    box-shadow: 0px 2px 5px 0px rgba(72, 117, 178, 0.15);
`;

const WorkItemContent = styled.div`
    justify-content: left;
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const WorkSummary = styled.div`
    margin-top: 1rem;
`;

const WorkTagsWrapper = styled.div`
    margin-top: 1rem;
    font-size: 15px;
    font-style: italic;
`;

const ViewMoreWrapper = styled.div`
    width: 100%;
    text-align: center;

    a {
        color: var(--textColor);
        text-decoration: none;
        cursor: pointer;

        &:hover {
            color: #0652dd;
            font-weight: bold;
        }
    }
`;

export const WorkItem = (workItemProps: WorkItem) => {
    const [visible, toggleVisibility] = useState(false);
    const breakpoints = useBreakpoint();
    const mobile = breakpoints.sm;
    return (
        <WorkItemWrapper
            onMouseEnter={() => toggleVisibility(true)}
            onMouseLeave={() => toggleVisibility(false)}
            mobile={mobile}
            itemIndex={workItemProps.itemIndex!}
        >
            <WorkItemContent>
                <WorkImage
                    mobile={mobile}
                    itemIndex={workItemProps.itemIndex!}
                    src={
                        mobile && workItemProps.mobileCoverImageUrl
                            ? workItemProps.mobileCoverImageUrl
                            : workItemProps.coverImageUrl
                    }
                    alt="DataWallet"
                />
                <WorkDescription mobile={mobile} itemIndex={workItemProps.itemIndex!}>
                    <Title
                        {...workItemProps.title}
                        accent={false}
                        marginBottom={0}
                        marginTop={0}
                        subTitle={workItemProps.type}
                        subTitleFontStyle="secondary"
                        alignment="left"
                        link={workItemProps.link}
                        linkText={workItemProps.link ? 'View Project' : undefined}
                    />
                    <WorkSummary>{workItemProps.summary}</WorkSummary>
                    <WorkTagsWrapper>
                        {workItemProps.tags?.map((tag: string, tagIndex: number) => (
                            <span key={`tag-${tagIndex}`}>
                                {tag} {workItemProps.tags && tagIndex < workItemProps.tags?.length - 1 ? '•' : ''}{' '}
                            </span>
                        ))}
                    </WorkTagsWrapper>
                </WorkDescription>
            </WorkItemContent>
        </WorkItemWrapper>
    );
};

export const Work = (workProps: WorkProps) => {
    return (
        <SectionWrapper dottedPattern={workProps.dottedPattern}>
            <Section id={workProps.id} title={workProps.title}>
                <WorkList>
                    {workProps.items?.map((workItem, workItemIndex) => (
                        <WorkItem itemIndex={workItemIndex} key={`work-item-${workItemIndex}`} {...workItem} />
                    ))}
                </WorkList>
                {/* <ViewMoreWrapper>
                    <Link to="/projects">
                        View More&nbsp;
                        <FontAwesomeIcon icon={faArrowRight} />
                    </Link>
                </ViewMoreWrapper> */}
            </Section>
        </SectionWrapper>
    );
};

export default Work;
